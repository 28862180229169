import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Dip not' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
      Bilginin gücüne inanlardan ve bu gücü tek taraflı hissetmek istemeyenlerden birisiyim. Bu minvalde sizlere cevap vermekten ve bilgimi aktarmaktan memnuniyet duyacağım.
      </Text>
    </Card>
  </Section>
)

export default Commitment
